<script>
  import { formatTimeToMins } from "./utils";
  import { range } from "lodash";

  export let timeLeftInSeconds,
    loyaltyInfo,
    locationNameId,
    loyaltyEnabled,
    button,
    styleName,
    onTimerClick,
    onButtonClick,
    onButtonTouch,
    isHardwareMode;
</script>

<link rel="stylesheet" href="/css/themes/commander11in.css" />
<link
  rel="stylesheet"
  href="/css/locations/{styleName ? styleName : locationNameId}.css"
/>
<link rel="stylesheet" href="/css/style.css" />
{#if isHardwareMode}
  <div class="hardwareButtonsLeft">
    {#each range(1, 5) as i}
      <div class="hardware-button" on:click={() => onButtonClick(i)}>
        <span class="terminal-button button{i}" />
      </div>
    {/each}
  </div>
  <div class="hardwareButtonsRight">
    {#each range(5, 9) as i}
      <div class="hardware-button" on:click={() => onButtonClick(i)}>
        <span class="terminal-button button{i}" />
      </div>
    {/each}
  </div>

  <div class="card-reader" on:click={onTimerClick} />
  <div class="qr-code-reader" on:click={onTimerClick} />
{/if}

<main>
  <div class="controls">
    <div
      class="infoDisplay"
      on:click={onTimerClick}
      class:beginscherm={timeLeftInSeconds == 0}
    >
      <h4>&nbsp;</h4>
      <div class="timer">
        <h1 id="time">{formatTimeToMins(timeLeftInSeconds)}</h1>
      </div>
    </div>
    <div class="buttonsLeft" class:beginscherm={timeLeftInSeconds == 0}>
      {#each range(1, 5) as i}
        <div
          class:active={button === 'button' + i && timeLeftInSeconds > 0}
          on:click={() => onButtonClick(i)}
          class="buttonTip button{i}"
        >
          <span class="touchit" on:click={() => onButtonTouch(i)}>&nbsp;</span>
          <span
            class="terminal-button button{i}"
            on:click={() => onButtonClick(i)}
          />
        </div>
      {/each}
      <div
        class:active={button === 'button' + 9 && timeLeftInSeconds > 0}
        on:click={() => onButtonClick(9)}
        class="buttonTip button9"
      >
        <span class="touchit" on:click={() => onButtonTouch(9)}>&nbsp;</span>
        <span
          class="terminal-button button9"
          on:click={() => onButtonClick(9)}
        />
      </div>
      <div
        class:active={button === 'button' + 11 && timeLeftInSeconds > 0}
        on:click={() => onButtonClick(11)}
        class="buttonTip button11"
      >
        <span class="touchit" on:click={() => onButtonTouch(11)}>&nbsp;</span>
        <span
          class="terminal-button button11"
          on:click={() => onButtonClick(11)}
        />
      </div>
      <div
        class:active={button === 'button' + 13 && timeLeftInSeconds > 0}
        on:click={() => onButtonClick(13)}
        class="buttonTip button13"
      >
        <span class="touchit" on:click={() => onButtonTouch(13)}>&nbsp;</span>
        <span
          class="terminal-button button13"
          on:click={() => onButtonClick(13)}
        />
      </div>
    </div>
    <div class="buttonsRight" class:beginscherm={timeLeftInSeconds == 0}>
      {#each range(5, 9) as i}
        <div
          class:active={button === 'button' + i && timeLeftInSeconds > 0}
          class:infobut={i === 8 && timeLeftInSeconds === 0}
          on:click={() => onButtonClick(i)}
          class="buttonTip button{i}"
        >
          <span class="touchit" on:click={() => onButtonTouch(i)}>&nbsp;</span>
          <span
            class="terminal-button button{i}"
            on:click={() => onButtonClick(i)}
          />
        </div>
      {/each}
      <div
        class:active={button === 'button' + 10 && timeLeftInSeconds > 0}
        on:click={() => onButtonClick(10)}
        class="buttonTip button10"
      >
        <span class="touchit" on:click={() => onButtonTouch(10)}>&nbsp;</span>
        <span
          class="terminal-button button10"
          on:click={() => onButtonClick(10)}
        />
      </div>
      <div
        class:active={button === 'button' + 12 && timeLeftInSeconds > 0}
        on:click={() => onButtonClick(12)}
        class="buttonTip button12"
      >
        <span class="touchit" on:click={() => onButtonTouch(12)}>&nbsp;</span>
        <span
          class="terminal-button button12"
          on:click={() => onButtonClick(12)}
        />
      </div>
    </div>
    <div
      class="infolayer"
      class:active={button === 'button8' && timeLeftInSeconds == 0}
    >
      &nbsp;
    </div>
    <!--hier extra info layer zetten 'beginlayer' en die dan in commander uit zetten,
      in de anderer style aan en dan active omdraaien met inactive na klik-->
  </div>

  <div
    class="screenContainer"
    class:loyalty={loyaltyEnabled}
    class:beginscherm={timeLeftInSeconds == 0}
  >
    {#if loyaltyInfo}
      <div class="loyalty-infos">
        <div class="hint">
          <img
            id="access-code-qr"
            src={loyaltyInfo.completeUrlQR}
            alt=""
            style="margin-bottom: 160px;"
          />
          <p>
            Kun je deze QR-code niet scannen? Ga dan naar:
            <a id="url" target="_blank" href={loyaltyInfo.url}>
              {loyaltyInfo.url}
            </a>
            en voer daar deze code in:
            <strong id="code">{loyaltyInfo.accessCode}</strong>
          </p>
        </div>
      </div>
    {/if}
  </div>
</main>

<style>
  .hardwareButtonsLeft {
    position: absolute;
    top: 105px;
    left: 100px;
    z-index: 1000;
  }

  .hardwareButtonsRight {
    position: absolute;
    top: 105px;
    left: 1115px;
    z-index: 1000;
  }

  .hardware-button {
    height: 100px;
    width: 80px;
    margin-bottom: 20px;
  }

  .card-reader {
    top: 694px;
    height: 368px;
    position: absolute;
    width: 300px;
    left: 83px;
    z-index: 1000;
  }

  .qr-code-reader {
    position: absolute;
    top: 811px;
    width: 200px;
    height: 200px;
    left: 547px;
    z-index: 1000;
  }
</style>
