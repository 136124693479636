<script>
  import { onMount } from "svelte";
  import { Timer } from "./timer";
  import TimerView from "./TimerView.svelte";
  import { navigate } from "svelte-routing";

  const socket = io();

  const TOKEN_KEY = 'static-token';

  let loyaltyEnabled = false;

  const locationNameId =
    new URLSearchParams(location.search).get("locationNameId") || "default";

  let styleName = new URLSearchParams(location.search).get("style");
  let isHardwareMode = new URLSearchParams(location.search).get("mode") === 'hardware';
  let isLiveMode = new URLSearchParams(location.search).get("mode") === 'live';

  if (isLiveMode) {
    const token = localStorage.getItem(TOKEN_KEY);

    if (!token) {
      const token = window.prompt('Enter the auth token:');
      localStorage.setItem(TOKEN_KEY, token);
    }

    const host = location.host.includes('localhost') ? 'http://localhost:3005' : "https://loyalty.server.savonatech.com"

    const client = io(`${host}/?token=${token}&location=${locationNameId}` , {
      withCredentials: false,
    });

    client.on('snapshot', (message) => {
      if (message && message.speedInMs) {
        const time = Number(message.timeLeftInSeconds);
        const speed = Number(message.speedInMs);

        timer.update(time, speed, locationNameId);

        if (message.button) {
          button = message.button;
        }

        if (speed === 6000000) {
          button = 'button0'
        }

        client.emit('ack', {
          id: message.id,
          incoming: message.date,
          date: new Date().toJSON()
        })
      }
    })

    client.on('request', (message) => {
      const time = Number(message.time);
      const speed = Number(message.speed);

      timer.update(time, speed, message.locationNameId);

      if (speed === 6000000) {
        button = 'button0'
      }

      client.emit('ack', {
        id: message.id,
        incoming: message.date,
        date: new Date().toJSON()
      })
    });

    client.on('button', (msg) => {
      if (msg.button) {
       button = msg.button;
      }

      client.emit('ack', {
        id: message.id,
        incoming: message.date,
        date: new Date().toJSON()
      })
    });
  }

  const socketChannelRequests = "request" + ":" + locationNameId;
  const socketChannelLoyalty = "timeChange" + ":" + locationNameId;
  const socketButtonChange = "update:button:" + locationNameId;

  console.log(
    "listening to",
    socketChannelRequests,
    socketChannelLoyalty,
    socketButtonChange
  );

  onMount(() => {
    checkForLoyalty();
    // recheck every x seconds
    setInterval(checkForLoyalty, 1000 * 120);
  });

  function checkForLoyalty() {
    fetch(`/api/loyalty-enabled?locationNameId=${locationNameId}`).then(
      (res) => {
        if (res.status === 200)
          return res.json().then((data) => {
            loyaltyEnabled = data;
          });
      }
    );
  }

  async function fetchConfig() {
    return fetch(`/api/config`).then((res) => {
      if (res.status === 200)
        return res.json().then((data) => {
          if (!data.location || (!styleName && !data.css)) {
            navigate("/svelte-ui/setup");
          }

          if (!styleName && data.css && data.location) {
            styleName = data.css.replace(".css", "");
          }
        });
    });
  }

  fetchConfig();

  const timer = Timer();

  let timeLeftInSeconds = 0;

  let loyaltyInfo;

  let button;

  timer.onChange((locationNameId, value) => {
    timeLeftInSeconds = value.timeLeftInSeconds;

    if (timeLeftInSeconds === 0) {
      button = 'button0'
    }
  });


  const buttonDemoMap = {
    0: 6000000,
    1: 499,
    2: 499,
    8: 6000000
  }

  async function onTimerClick() {
    if (locationNameId.includes('demo')) {
      const lastSpeed = timer.timerMap[locationNameId] ?  timer.timerMap[locationNameId].speed : buttonDemoMap[8];
      await fetch(`/api/add-time-demo?time=${60}&locationNameId=${locationNameId}&speed=${lastSpeed}`);
      timer.update(60, lastSpeed, locationNameId);
      button = 'button0';
    }
  }

  async function onButtonClick(_button) {
    console.log(_button);
    if (locationNameId.includes('demo') && timer.timerMap[locationNameId] && _button) {
      const { timeLeftInSeconds } = timer.timerMap[locationNameId];
      const speed = buttonDemoMap[_button] || 999;
      button = `button${_button}`;
      timer.update(timeLeftInSeconds, speed, locationNameId);
      await fetch(`/api/button-click-demo?button=${button}&locationNameId=${locationNameId}&time=${timeLeftInSeconds}&speed=${speed}`);
    }
  }

  async function onButtonTouch(_button) {
    button = `button${_button}`;
    await fetch(`http://localhost:1880/app?button=${button}`);
  }

  socket.on("refresh", () => {
    location.reload();
  });

  socket.on(socketButtonChange, function (message) {
    if (message.button) {
      button = message.button;
    }
  });

  socket.on(socketChannelRequests, function (message) {
    const time = Number(message.time);
    const speed = Number(message.speed);

    timer.update(time, speed, message.locationNameId);

    if (speed === 6000000) {
      button = 'button0'
    }

  });

  socket.on(socketChannelLoyalty, function (message) {
    if (message.loyalty && loyaltyEnabled) {
      console.log(message.loyalty);
      loyaltyInfo = message.loyalty;
    } else {
      loyaltyInfo = undefined;
    }
  });

</script>

<TimerView
  {styleName}
  {button}
  {timeLeftInSeconds}
  {loyaltyInfo}
  {loyaltyEnabled}
  {locationNameId}
  {isHardwareMode}
  {onTimerClick}
  {onButtonClick}
  {onButtonTouch}
/>
