<script>
  import { range, sortBy } from "lodash";
  import { navigate } from "svelte-routing";

  let locations = [];
  let config = [];
  let cssFiles = [];
  let button = "button1";
  let timeLeftInSeconds = 1;
  async function fetchLocations() {
    return fetch(`/api/locations`).then((res) => {
      if (res.status === 200)
        return res.json().then((data) => {
          locations = sortBy(data, (loc) => loc.registered);
        });
    });
  }

  async function fetchConfig() {
    return fetch(`/api/config`).then((res) => {
      if (res.status === 200)
        return res.json().then((data) => {
          config = data;
          selectedLocation = locations.find(
            (loc) => loc.nameId === data.location
          ).nameId;
          selectedCSS = cssFiles.find((css) => css === data.css);
        });
    });
  }

  async function fetchCssFiles() {
    return fetch(`/api/css-files`).then((res) => {
      if (res.status === 200)
        return res.json().then((data) => {
          cssFiles = data;
        });
    });
  }

  function saveConfig() {
    const locationId = locations.find((loc) => loc.nameId === selectedLocation);
    fetch(`/api/save-config`, {
      method: "POST",
      body: JSON.stringify({
        location: selectedLocation,
        locationId: locationId.id,
        css: selectedCSS,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status === 200) {
        locations = locations.map((loc) => {
          if (loc.id === locationId) {
            loc.registered = true;
          }
          return loc;
        });
        alert("Configuration saved");
      } else {
        alert("Failed to save configuration!");
      }
    });
  }

  let selectedLocation = null;
  let selectedCSS = null;

  function selectLocation(location) {
    console.log(location);
    selectedLocation = location.nameId;
  }

  function selectCSS(file) {
    selectedCSS = file;
  }

  function goToTimer() {
    navigate("/svelte-ui/");
  }

  fetchLocations().then(fetchCssFiles).then(fetchConfig);

</script>

{#if selectedCSS}
  <link rel="stylesheet" href="/css/style.css" />
  <link rel="stylesheet" href="/css/themes/commander11in.css" />
  <link rel="stylesheet" href="/css/locations/{selectedCSS}" />
{/if}

<div class="container">
  <div class="dropdown">
    <button class="dropbtn">Select Location</button>
    <div class="dropdown-content">
      {#each locations as location}
        <div on:click={() => selectLocation(location)}>
          {location.nameId}
          {location.registered ? " ✓" : ""}
        </div>
      {/each}
    </div>
  </div>
  <quote style="color: white"
    >✓ is for locations that are already registered</quote
  >
  <br />

  {#if selectedLocation}
    <h2 style="color: white">{selectedLocation}</h2>
  {/if}

  <br />

  <div class="dropdown">
    <button class="dropbtn">Select Style</button>
    <div class="dropdown-content">
      {#each cssFiles as cssFile}
        <div on:click={() => selectCSS(cssFile)}>{cssFile}</div>
      {/each}
    </div>
  </div>

  {#if selectedCSS}
    <h2 style="color: white">{selectedCSS}</h2>
  {/if}

  <br />
  <button
    class="save-button"
    disabled={!selectedCSS || !selectedLocation}
    on:click={saveConfig}>Save</button
  >
  <br />
  <button class="timer-button" on:click={goToTimer}>Go to timer</button>
</div>

{#if selectedCSS}
  <main>
    <div class="controls">
      <div class="infoDisplay">
        <h4>Tijd over:</h4>
        <div class="timer">
          <h1 id="time">00:00</h1>
        </div>
      </div>
      <div class="buttonsLeft">
        {#each range(1, 5) as i}
          <div
            class:active={button === "button" + i && timeLeftInSeconds > 0}
            class="buttonTip button{i}"
          />
        {/each}
      </div>
      <div class="buttonsRight">
        {#each range(5, 9) as i}
          <div
            class:active={button === "button" + i && timeLeftInSeconds > 0}
            class:infobut={i == 8 && timeLeftInSeconds == 0}
            class="buttonTip button{i}"
          />
        {/each}
      </div>
    </div>
    <div class="screenContainer" class:loyalty={false} />
  </main>
{/if}

<style>
  .container {
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    position: absolute;
    top: 100px;
    left: 640px;
    z-index: 1000;

    padding: 10px;
    background: grey;
    transform: translateX(-50%);
  }

  .save-button {
    cursor: pointer;
    padding: 10px;
    background: beige;
    border: none;
    width: 100%;
    font-size: larger;
  }

  .timer-button {
    cursor: pointer;
    padding: 10px;
    background: azure;
    border: none;
    width: 100%;
    font-size: larger;
  }

  /* Style The Dropdown Button */
  .dropbtn {
    background-color: #87cefa;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }

  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    position: relative;
    display: inline-block;
  }

  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    max-height: 400px;
    overflow: auto;

    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 250px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  /* Links inside the dropdown */
  .dropdown-content div {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  /* Change color of dropdown links on hover */
  .dropdown-content div:hover {
    background-color: #f1f1f1;
  }

  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {
    display: block;
  }

  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }

</style>
