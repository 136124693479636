const handler = {
  set: function (obj, prop, value) {
    if (obj[prop] !== value) {
      obj[prop] = value;
      obj.timestamp = new Date();
      obj.onChange("default", obj);
    }
    return true;
  },
};

let onChangeCallback = null;

export const Timer = () => {
  const timerMap = {};

  return {
    timerMap,
    onChange(callback) {
      onChangeCallback = callback;
    },
    update(time, speedInMs, location) {
      if (timerMap[location] && timerMap[location].countdown) {
        clearInterval(timerMap[location].countdown);
      }

      if (!timerMap[location]) {
        timerMap[location] = new Proxy(
          {
            onChange: (...args) =>
              onChangeCallback ? onChangeCallback(...args) : null,
            timeHistory: [],
          },
          handler
        );
      }

      timerMap[location].location = location;
      timerMap[location].loyalty = null;
      timerMap[location].speed = speedInMs;
      timerMap[location].timeLeftInSeconds = time;
      timerMap[location].timeHistory.push(time);

      const tickingSpeed = speedInMs - 1;

      timerMap[location].countdown = setInterval(async () => {
        if (timerMap[location].timeLeftInSeconds > 0) {
          timerMap[location].timeLeftInSeconds -= 1;
        }
        if (timerMap[location].timeLeftInSeconds === 0) {
          clearInterval(timerMap[location].countdown);
          timerMap[location].timeHistory = [];
        }
      }, tickingSpeed);
    },
  };
};
