<script>
  import { Router, Link, Route } from "svelte-routing";
  import DashboardRedirect from "./DashboardRedirect.svelte";
  import Timer from "./Timer.svelte";
  import Setup from "./Setup.svelte";

  export let url = "";

</script>

<!-- App.svelte -->
<Router {url} basepath="/svelte-ui">
  <div>
    <Route path="/">
      <Timer />
    </Route>
    <Route path="/dashboard" component={DashboardRedirect} />
    <Route path="/setup" component={Setup} />
  </div>
</Router>
