<script>
const token = new URLSearchParams(location.search).get('token')
</script>

<style>
</style>

<main>
  <h1 style="text-align: center;">
    Dashboard was moved to <a href={'https://loyalty.server.savonatech.com/dashboard?token=' + (token || '')}>a new location</a>
  </h1>
</main>
